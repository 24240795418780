{
  "name": "s2a-testing-front",
  "version": "1.10.6",
  "versionNumber": "2004",
  "author": "Sully Group",
  "homepage": "https://sully-group.fr/",
  "description": "Salomon S2A Testing",
  "private": true,
  "scripts": {
    "start": "ionic serve --open",
    "start:production": "ionic serve -c production --open",
    "assets:recette": "capacitor-assets generate --assetPath ./assets/recette",
    "assets:qualification": "capacitor-assets generate --assetPath ./assets/qualification",
    "assets:production": "capacitor-assets generate --assetPath ./assets/production",
    "build:development": "ionic build -c dev",
    "build:integration": "ionic build -c integration",
    "build:recette": "ionic build -c recette",
    "build:qualification": "ionic build -c qualification",
    "build:production": "ionic build -c production",
    "sync:android:recette": "cross-env NODE_ENV=recette ionic cap sync android --no-build",
    "sync:android:qualification": "cross-env NODE_ENV=qualification ionic cap sync android --no-build",
    "sync:android:production": "cross-env NODE_ENV=production ionic cap sync android --no-build",
    "sync:ios:recette": "cross-env NODE_ENV=recette ionic cap sync ios --no-build",
    "sync:ios:qualification": "cross-env NODE_ENV=qualification ionic cap sync ios --no-build",
    "sync:ios:production": "cross-env NODE_ENV=production ionic cap sync ios --no-build",
    "open:android": "ionic cap open android",
    "open:ios": "ionic cap open ios",
    "android": "ionic cap run android -l --external",
    "ios": "ionic cap run ios -l --external",
    "prettier": "prettier --write --config ./.prettierrc --list-different \"src/{app,environments,assets}/**/*{.ts,.js,.json,.css,.scss}\""
  },
  "dependencies": {
    "@angular/common": "17.1.1",
    "@angular/core": "17.1.1",
    "@angular/forms": "17.1.1",
    "@angular/platform-browser": "17.1.1",
    "@angular/platform-browser-dynamic": "17.1.1",
    "@angular/router": "17.1.1",
    "@capacitor-community/file-opener": "^1.0.5",
    "@capacitor-community/native-market": "^5.0.0",
    "@capacitor/android": "^5.6.0",
    "@capacitor/app": "5.0.7",
    "@capacitor/browser": "^5.0.6",
    "@capacitor/camera": "5.0.9",
    "@capacitor/core": "^5.6.0",
    "@capacitor/filesystem": "5.2.1",
    "@capacitor/haptics": "5.0.7",
    "@capacitor/ios": "^5.6.0",
    "@capacitor/keyboard": "5.0.8",
    "@capacitor/network": "5.0.7",
    "@capacitor/preferences": "^5.0.7",
    "@capacitor/push-notifications": "5.1.1",
    "@capacitor/screen-orientation": "^5.0.7",
    "@capacitor/splash-screen": "5.0.7",
    "@capacitor/status-bar": "5.0.7",
    "@ionic/angular": "^7.7.1",
    "@ionic/storage": "^4.0.0",
    "@ionic/storage-angular": "^4.0.0",
    "@meddv/ngx-pinch-zoom": "^17.0.1",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@sentry/angular-ivy": "^7.81.1",
    "@sentry/capacitor": "^0.15.1",
    "@sentry/tracing": "7.81.1",
    "chart.js": "^4.4.1",
    "chartjs-plugin-datalabels": "^2.2.0",
    "compare-versions": "^6.1.0",
    "date-fns": "3.3.1",
    "dexie": "^3.2.7",
    "file-saver": "^2.0.5",
    "ionicons": "^7.2.2",
    "jwt-decode": "^4.0.0",
    "moment": "^2.30.1",
    "rxjs": "~7.8.1",
    "swiper": "^11.0.6",
    "uuid": "^9.0.1",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "17.1.1",
    "@angular-eslint/builder": "17.2.1",
    "@angular-eslint/eslint-plugin": "17.2.1",
    "@angular-eslint/eslint-plugin-template": "17.2.1",
    "@angular-eslint/template-parser": "17.2.1",
    "@angular/cli": "17.1.1",
    "@angular/compiler": "17.1.1",
    "@angular/compiler-cli": "17.1.1",
    "@angular/language-service": "17.1.1",
    "@capacitor/assets": "^3.0.4",
    "@capacitor/cli": "^5.6.0",
    "@ionic/angular-toolkit": "11.0.1",
    "@ionic/cli": "^7.2.0",
    "@types/file-saver": "^2.0.7",
    "@types/jasmine": "~5.1.4",
    "@types/jasminewd2": "2.0.13",
    "@types/node": "20.11.10",
    "@types/uuid": "^9.0.8",
    "@typescript-eslint/eslint-plugin": "6.18.1",
    "@typescript-eslint/parser": "6.18.1",
    "cross-env": "^7.0.3",
    "eslint": "^8.56.0",
    "eslint-plugin-import": "^2.29.1",
    "eslint-plugin-jsdoc": "48.0.2",
    "eslint-plugin-prefer-arrow": "1.2.3",
    "jasmine-core": "~5.1.1",
    "jasmine-spec-reporter": "~7.0.0",
    "karma": "~6.4.2",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-coverage-istanbul-reporter": "3.0.3",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "^2.1.0",
    "prettier": "^3.2.4",
    "run-script-os": "^1.1.6",
    "ts-node": "~10.9.2",
    "tslib": "^2.6.2",
    "typescript": "~5.3.3"
  },
  "browserslist": [
    "last 1 Chrome version",
    "last 1 Firefox version",
    "last 2 Edge major versions",
    "last 2 Safari major versions",
    "last 2 iOS major versions",
    "Firefox ESR",
    "not IE 11 "
  ]
}
